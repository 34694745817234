import React from 'react';
import classnames from 'classnames';
import { get, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import {
  resolveId,
  isRichContent,
  isExperimentEnabled,
  EXPERIMENTS,
} from '@wix/communities-blog-client-common';
import { PaywallBanner } from '@wix/communities-blog-client-common/dist/esm/components';
import RichContentViewer from '../../../rich-content/components/rich-content-viewer';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../hoc/with-translate';
import { getAppSettings } from '../../selectors/app-settings-base-selectors';
import { getSection } from '../../selectors/section-selectors';
import { getPaywallBanner } from '../../store/paywall/paywall-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import connect from '../runtime-context/connect';
import styles from './post-content.scss';

const PaywallBannerWithLink = ({
  appSettings,
  section,
  paywallContent,
  subscribeURL,
}) => {
  const isDraftJS = !isRichContent(paywallContent);
  const modifiedPaywallContent = React.useMemo(() => {
    try {
      if (isDraftJS) {
        const stringifiedContent = JSON.stringify(paywallContent);
        return JSON.parse(
          stringifiedContent
            .replace('"url":""', `"url": "${subscribeURL}"`)
            .replace('"target":true', '"target":"SELF"')
            .replace(
              'wix-draft-plugin-action-button',
              'wix-draft-plugin-link-button',
            ),
        );
      } else {
        paywallContent.nodes[2].buttonData.link = {
          url: subscribeURL,
          target: 'SELF',
          rel: {
            nofollow: true,
            sponsored: false,
          },
        };

        return paywallContent;
      }
    } catch {
      return paywallContent;
    }
  }, [isDraftJS, paywallContent, subscribeURL]);

  return (
    <PaywallBanner
      banner={modifiedPaywallContent}
      viewer={RichContentViewer}
      appSettings={appSettings}
      section={section}
      className={styles.banner}
    />
  );
};

export function PostContent({
  t,
  post,
  contentFontClassName,
  appSettings,
  section,
  banner,
  navigateToPaidPlans,
  pricingPlansUrl,
  topology,
  useLinkForSubscribeNow,
} = {}) {
  const paywallContent =
    banner ?? post?.paywallRichContent ?? post?.paywallContent;
  const postContentBodyClass = 'post-content__body';

  return (
    <article className={contentFontClassName}>
      <div className={classnames(postContentBodyClass, styles.rceNextView)}>
        {typeof post.content === 'object' && (
          <RichContentViewer
            initialRawState={post.content}
            post={post}
            parentClass={postContentBodyClass}
          />
        )}
      </div>
      {get(post, 'canSeePaidContent') === false && paywallContent && (
        <div className={styles.paywallContainer}>
          {useLinkForSubscribeNow ? (
            <PaywallBannerWithLink
              section={section}
              appSettings={appSettings}
              paywallContent={paywallContent}
              subscribeURL={new URL(topology.baseUrl + pricingPlansUrl).href}
            />
          ) : (
            <PaywallBanner
              banner={paywallContent}
              viewer={RichContentViewer}
              appSettings={appSettings}
              section={section}
              className={styles.banner}
              onSubscribe={() =>
                navigateToPaidPlans(
                  resolveId(post),
                  post.paidPlansGroupIds,
                  post.slug,
                  t('subscription.thanks-page.title'),
                  t('subscription.thanks-page.text'),
                  t('subscription.thanks-page.continue-button'),
                )
              }
            />
          )}
        </div>
      )}
    </article>
  );
}

PostContent.propTypes = {
  t: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  appSettings: PropTypes.object.isRequired,
  section: PropTypes.string,
  banner: PropTypes.object,
  navigateToPaidPlans: PropTypes.func.isRequired,
  topology: PropTypes.object,
  useLinkForSubscribeNow: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  appSettings: getAppSettings(state),
  section: getSection(state, false),
  banner: getPaywallBanner(state),
  navigateToPaidPlans: actions.navigateToPaidPlans,
  pricingPlansUrl: state.pricingPlansUrl,
  topology: getTopology(state),
  useLinkForSubscribeNow: isExperimentEnabled(
    state,
    EXPERIMENTS.USE_LINK_FOR_PAYWALL_SUBSCRIBE_NOW_BUTTON,
  ),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withPermissions,
)(PostContent);
